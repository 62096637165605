<template>
  <div>
    <section class="hero">
      <div class="container stretch">
        <div class="columns is-multiline is-align-items-center z-2">
          <div class="column">
            <h1 class="hero__title mb-3">Change My Subscription</h1>
            <div class="hero__content">
              <p class="mb-4">Hi {{ $auth.user.firstName }}, you are able to change your subscription here.</p>
              <p class="mb-4"> SOME TEXT HERE IF REQUIRED.</p>
              
            </div>
          </div>
        </div>
        <img
          src="@/assets/slide_image_estia.png"
          alt=""
          class="is-hidden-mobile"
        />
      </div>
    </section>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  layout: "default",
  middleware: ['auth', 'parent'],
  head: {
    title: "EstiaLabs - Change Subscription"
  }
});
</script>

<style scoped>
.membersBtn {
  width: 14rem;
}
.hero {
  flex-direction: row;
}

@media screen and (min-width: 769px), print {
  .columns:not(.is-desktop) {
    display: block;
    margin-top: 3rem;
  }
}
</style>